import apiRequest from "../../@core/axios";

const SET_ALL_INSURANCE_PLANS = 'SET_ALL_INSURANCE_PLANS'
const SET_INSURANCE_PLAN_DETAILS = 'SET_INSURANCE_PLAN_DETAILS'

export const initialState = {
    insurances: {
        data: [],
        total: 0,
        per_page: 5,
        current_page: 1,
    },
    insurancePlanDetails: {}
}

export function InsurancePlansReducer(state = initialState, action: any = {}) {
    switch (action.type) {
        case "SET_ALL_INSURANCE_PLANS":
            return {
                ...state,   
                insurances: action.payload
            };

        case "SET_INSURANCE_PLAN_DETAILS":
            return {
                ...state,   
                insurancePlanDetails: action.payload
            };

        default:
            return state;
    }
}

export function setAllInsurancePlans(data: any) {
    return {
      type: SET_ALL_INSURANCE_PLANS,
      payload: data,
    };
  }

export function setInsurancePlanDetails(data: any) {
    return {
      type: SET_INSURANCE_PLAN_DETAILS,
      payload: data,
    };
  }

export function getInsurancePlanDetails(insurance_id: any): Promise<any> {
    return apiRequest({
        url: `insurances/${insurance_id}`,
        method: "GET",
    });
}

export function getInsurancePlansList(data: any): Promise<any> {
    return apiRequest({
        url: `broker/insurances`,
        method: "GET",
        ...data
    });
}

export function getSSInsurancePlansList(data: any): Promise<any> {
  return apiRequest({
      url: `insurances/included-in-ss`,
      method: "GET",
  });
}


export function addInsurancePlan(data: any): Promise<any> {
    return apiRequest({
      url: "insurances",
      method: "POST",
      data,
    });
  }

export function updateInsurancePlan(insurance_id: any, data: any): Promise<any> {
    return apiRequest({
      url: `insurances/${insurance_id}/update`,
      method: "POST",
      data,
    });
  }

export function deleteInsurancePlan(insurance_id: any, data = {}): Promise<any> {
    return apiRequest({
      url: `insurances/${insurance_id}`,
      method: "DELETE",
      data,
    });
  }