import { Container, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import cx from "classnames";
import React from "react";
import bigLogo from "../../assets/imgs/Group 2.png";
import interior_hero_bg from "../../assets/imgs/interior_hero_bg1.png";
import miniLogo from "../../assets/imgs/mini-logo.svg";
import APP_CONFIG from "../../config";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

export default function Footer(props: any) {
  // console.log(props.alignItems);
  const classes = useStyles();
  const { t } = useTranslation();
  const history=useHistory();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const contentBox = (
    color: any,
    topic: String,
    date: String,
    heading: String,
    content: any
  ) => {
    return (
      <div className={classes.cotainer}>
        <div>
          <span
            className={cx(
              classes.topic,
              color === "color1"
                ? classes.color1
                : color === "color2"
                ? classes.color2
                : classes.color3
            )}
          >
            {topic}
          </span>
          <span className={classes.date}> {date}</span>
        </div>
        <h3 className={classes.heading}>{heading}</h3>
        <div className={classes.content}>{content}</div>
      </div>
    );
  };

  const releaseVersion = () => (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className={classes.dialogContentRoot}>
        <div id="alert-dialog-title" className={classes.dialog}>
          <div className={classes.close} onClick={handleClose}>
            <CloseIcon />
          </div>

          <img src={bigLogo} />
          <div className={classes.dialogTitle}>
            Release Notes
            <br /> {APP_CONFIG.APP_VERSION}
          </div>
        </div>
        <div className={classes.dialogContent}>

        {contentBox(
            "color1",
            "Features/ Bug Fixes/ Hidding Some Refinance Sections",
            "March 13, 2023",
            "Feature Release Notes",
            <div>
              <ul>
              <li> <a target="_blank" href=""> TMS 870 </a> Adjust field so it does not revert back to original value</li>
              <li> <a target="_blank" href=""> TMS 869 </a> FE - Red/Green feature</li>
              <li> <a target="_blank" href=""> TMS 868 </a> Rate disclaimers in Strategy Sessions</li>
              <li> <a target="_blank" href=""> TMS 867 </a>alphabetical order in the lender drop-down box is not showing. Can you please adjust</li>
              <li> <a target="_blank" href=""> TMS 863 </a> FE-Include term in rate comparison</li>
              </ul>
            </div>
          )}  

        {contentBox(
            "color1",
            "Features/ Bug Fixes/ Hidding Some Refinance Sections",
            "December 09, 2022",
            "Feature Release Notes",
            <div>
              <ul>
              <li> <a target="_blank" href=""> TMS 850 </a> Hidding tutorials and DP improvement</li>
              <li> <a target="_blank" href=""> TMS 847 </a> Update Rate Plan interest rate filed not adjusting correctly</li>
              <li> <a target="_blank" href=""> TMS 842 </a> HSP - DP not responding quickly</li>
              <li> <a target="_blank" href=""> TMS 841 </a> Home Sale & Purchase - Take "Deposit Made On Purchase" row out</li>
              <li> <a target="_blank" href=""> TMS 840 </a> Home Refinance - Take penalty out of "Refinance Costs"</li>
              <li> <a target="_blank" href=""> TMS 838 </a> Privacy Policy - Email received by subscribers and clients</li>
              <li> <a target="_blank" href=""> TMS 836 </a> when you “Update Rate Plan” the compounding does not change and no need for the drop down arrow</li>
              <li> <a target="_blank" href=""> TMS 835 </a> Export to PDF has TMS Logo & Estimated Monthly Costs are not summarized</li>
              <li> <a target="_blank" href=""> TMS 832 </a> Create accept the privacy policy in sing up the flow</li>
              <li> <a target="_blank" href="">  </a> </li>
              </ul>
            </div>
          )}  

        {contentBox(
            "color1",
            "Features/ Bug Fixes",
            "October 28, 2022",
            "Feature Release Notes",
            <div>
              <ul>
              <li> <a target="_blank" href=""> TMS 830 </a> Link between Prime Rate admin and frontend</li>
              <li> <a target="_blank" href=""> TMS 827 </a> FE- Client requested changes 10oct22</li>
              <li> <a target="_blank" href=""> TMS 825 </a> Info Buttons in Mortgage Product Options section</li>
              <li> <a target="_blank" href=""> TMS 824 </a> Spelling of homeowner in this ‘info’ area of all HP and HSP</li>
              <li> <a target="_blank" href=""> TMS 823 </a> FE- Client requested changes 29sept22</li>
              <li> <a target="_blank" href=""> TMS 822 </a> FE-Improvement Program changes</li>
              <li> <a target="_blank" href=""> TMS 820 </a> FE-Client request 22Sept22</li>
              <li> <a target="_blank" href="">  </a> </li>
              </ul>
            </div>
          )}  
        {contentBox(
            "color1",
            "Features/ Bug Fixes",
            "September 23, 2022",
            "Feature Release Notes",
            <div>
              <ul>
              <li> <a target="_blank" href=""> TMS 818 </a> FE-Client requested changes 17sept22</li>
              <li> <a target="_blank" href=""> TMS 816 </a> Quick question re 'Notifications'</li>
              <li> <a target="_blank" href=""> TMS 815 </a> Home Refinance SS 'server error - email Sep 09 at 11:37 pm</li>
              <li> <a target="_blank" href=""> TMS 814 </a> FE-Client requested changes 08 sept2022</li>
              <li> <a target="_blank" href=""> TMS 812 </a> FE-Refinance Printing</li>
              <li> <a target="_blank" href="">  </a> </li>
              </ul>
            </div>
          )}  

        {contentBox(
            "color1",
            "Features/ Bug Fixes",
            "September 06, 2022",
            "Feature Release Notes",
            <div>
              <ul>
              <li> <a target="_blank" href=""> TMS 810 </a> FE- Client requested changes 02/09</li>
              <li> <a target="_blank" href=""> TMS 809 </a> FE-Social networks issue</li>
              <li> <a target="_blank" href=""> TMS 808 </a> FE-Client requested changes 01/09</li>
              <li> <a target="_blank" href=""> TMS 807 </a> MPO amortization bug</li>
              <li> <a target="_blank" href=""> TMS 805 </a> FE- SS creation issue </li>
              <li> <a target="_blank" href="">  </a> </li>
              </ul>
            </div>
          )}  

        {contentBox(
            "color1",
            "Features/ Bug Fixes",
            "August 22, 2022",
            "Feature Release Notes",
            <div>
              <ul>
              <li> <a target="_blank" href=""> TMS 803 </a> Email Aug 18 - 1:50 PM Issues Ref 46</li>
              <li> <a target="_blank" href=""> TMS 802 </a> Russell email Aug 17 - 4:07 PM email (Ref 45)</li>
              <li> <a target="_blank" href=""> TMS 801 </a> FE- Beta tester feedbacks</li>
              <li> <a target="_blank" href=""> TMS 799 </a> FE- Duplication amortization issue </li>
              <li> <a target="_blank" href=""> TMS 798 </a> FE- PDF print number formatting </li>
              <li> <a target="_blank" href="">  </a> </li>
              </ul>
            </div>
          )}  

        {contentBox(
            "color1",
            "Features/ Bug Fixes",
            "August 15, 2022",
            "Feature Release Notes",
            <div>
              <ul>
              <li> <a target="_blank" href=""> TMS 796 </a> Email Aug 10 - 2 PM Issues</li>
              <li> <a target="_blank" href=""> TMS 795 </a> Preparation for testing session</li>
              <li> <a target="_blank" href=""> TMS 794 </a> FE- Autofill email from search in SS clients</li>
              <li> <a target="_blank" href=""> TMS 793 </a> Create New Strategy Session - Adding clients/contacts (Ref 38)</li>
              <li> <a target="_blank" href=""> TMS 792 </a> Account - URL in Insurance section of subscribers Account (Ref 37)</li>
              <li> <a target="_blank" href=""> TMS 791 </a> FE-Login logo update endpoint </li>
              <li> <a target="_blank" href=""> TMS 790 </a> FE-Other contact SS endpoints </li>
              <li> <a target="_blank" href="">  </a> </li>
              </ul>
            </div>
          )}  

        {contentBox(
            "color1",
            "Features/ Bug Fixes",
            "July 29, 2022",
            "Feature Release Notes",
            <div>
              <ul>
              <li> <a target="_blank" href=""> TMS 789 </a> FE- clients requested changes 28/07</li>
              <li> <a target="_blank" href=""> TMS 787 </a> Please adjust the decimal points in the PPIRP section under the Monthly Payment (Ref 35) </li>
              <li> <a target="_blank" href=""> TMS 784 </a> Front-end: Cancel Auto-renewal on Subscription </li>
              <li> <a target="_blank" href="">  </a> </li>
              </ul>
            </div>
          )}  
        
        {contentBox(
            "color1",
            "Features/ Bug Fixes",
            "July 18, 2022",
            "Feature Release Notes",
            <div>
              <ul>
              <li> <a target="_blank" href=""> TMS 780 </a> Adding Clients/Mortgage Team Members/Other - Info Buttons (Line - 33)</li>
              <li> <a target="_blank" href=""> TMS 779 </a> FE- Payment and company logo change</li>
              <li> <a target="_blank" href=""> TMS 778 </a> FE- Changes from Meeting 12/07 </li>
              <li> <a target="_blank" href=""> TMS 777 </a> Rate Comparisons/Rate Plan (Point 32) </li>
              <li> <a target="_blank" href="">  </a> </li>
              </ul>
            </div>
          )}  
        {contentBox(
            "color1",
            "Features/ Bug Fixes",
            "July 11, 2022",
            "Feature Release Notes",
            <div>
              <ul>
              <li> <a target="_blank" href=""> TMS 776 </a> FE-Client interface changes</li>
              <li> <a target="_blank" href=""> TMS 774 </a> BE - Budgeting For Sales Costs Changes</li>
              <li> <a target="_blank" href=""> TMS 772 </a> Rate Comparisons/Rate Plan other </li>
              <li> <a target="_blank" href=""> TMS 771 </a> Edit button and renaming (Line 32) </li>
              <li> <a target="_blank" href="">  </a> </li>
              </ul>
            </div>
          )}  
        {contentBox(
            "color1",
            "Features/ Bug Fixes",
            "June 30, 2022",
            "Feature Release Notes",
            <div>
              <ul>
              <li> <a target="_blank" href=""> TMS 769 </a> FE - Resend Access URL Change</li>
              <li> <a target="_blank" href=""> TMS 767 </a> Delay on entering data</li>
              <li> <a target="_blank" href=""> TMS 764 </a> FE - Various tooltip updates from excelsheet </li>
              <li> <a target="_blank" href="">  </a> </li>
              </ul>
            </div>
          )}  

        {contentBox(
            "color1",
            "Features/ Bug Fixes",
            "June 17, 2022",
            "Feature Release Notes",
            <div>
              <ul>
                <li> <a target="_blank" href=""> TMS 764 </a> Rental Property update - Info Buttons update</li>
                <li> <a target="_blank" href=""> TMS 763 </a> Strategy Session - RPP Ontario</li>
                <li> <a target="_blank" href=""> TMS 760 </a> FE - Learn more for different client types </li>
                <li> <a target="_blank" href="">  </a> </li>
              </ul>
            </div>
          )} 

        {contentBox(
            "color1",
            "Features/ Bug Fixes",
            "June 10, 2022",
            "Feature Release Notes",
            <div>
              <ul>
                <li> <a target="_blank" href=""> TMS 759 </a> FE - Strategy Plan Province Bug Fix</li>
                <li> <a target="_blank" href=""> TMS 758 </a> FE - List Payment Invoices</li>
                <li> <a target="_blank" href=""> TMS 756 </a> FE - Bug Fixes</li>
                <li> <a target="_blank" href=""> TMS 752 </a> FE - UI & UX Adjustments</li>
                <li> <a target="_blank" href=""> TMS 750 </a> FE - Debounce Bug Fix</li>
                <li> <a target="_blank" href=""> TMS 661 </a> Update Product Rate Card</li>
                <li> <a target="_blank" href="">  </a> </li>
              </ul>
            </div>
          )} 

        {contentBox(
            "color1",
            "Features/ Bug Fixes",
            "May 30, 2022",
            "Feature Release Notes",
            <div>
              <ul>
                <li> <a target="_blank" href=""> TMS 749 </a> FE - Loom Video May 29</li>
                <li> <a target="_blank" href=""> TMS 746 </a> FE - Home Refinance Bugs</li>
                <li> <a target="_blank" href=""> TMS 745 </a> FE - Fix the Auto Populate Button in Mortgage Product Options</li>
                <li> <a target="_blank" href=""> TMS 744 </a> FE - Disable Add Strategy Plan Button when Limit Reached</li>
                <li> <a target="_blank" href=""> TMS 743 </a> FE - Company Accept Invitation Screen</li>
                <li> <a target="_blank" href=""> TMS 742 </a> FE - Support Topics</li>
                <li> <a target="_blank" href=""> TMS 741 </a> FE - SS Screen UI Fixes</li>
                <li> <a target="_blank" href=""> TMS 740 </a> FE - Client View</li>
                <li> <a target="_blank" href=""> TMS 739 </a> FE - Interest Rate Dialog Adjustments in Home Refinance</li>
                <li> <a target="_blank" href=""> TMS 738 </a> FE - Flexibility Rating in Mortgage Product Options</li>
                <li> <a target="_blank" href=""> TMS 737 </a> FE - Debounce Change (Rental Property Purchase)</li>
                <li> <a target="_blank" href=""> TMS 733 </a> FE - Debounce Logic Change (Continue)-Focus issue solving</li>
                <li> <a target="_blank" href="">  </a> </li>
              </ul>
            </div>
          )} 

        {contentBox(
            "color1",
            "Features/ Bug Fixes",
            "May 12, 2022",
            "Feature Release Notes",
            <div>
              <ul>
             
                <li> <a target="_blank" href=""> TMS 736 </a> FE - Debounce Change (Home Refinance)</li>
                <li> <a target="_blank" href=""> TMS 735 </a> Update the Tooltips</li>
                <li> <a target="_blank" href=""> TMS 734 </a> FE - Property Transfer Tax Alignment Enhancement</li>
                <li> <a target="_blank" href=""> TMS 733 </a> FE - Debounce Logic Change (Continue)</li>
                <li> <a target="_blank" href=""> TMS 732 </a> FE - SS Edit Contacts Change</li>
                <li> <a target="_blank" href=""> TMS 731 </a> FE - New Team Members Screen Implementation</li>
                <li> <a target="_blank" href=""> TMS 730 </a> FE - New Billing Screen Implementation</li>
                <li> <a target="_blank" href=""> TMS 719 </a>FE - Subscription Purchase Flow Review & Bug Fix</li>
                <li> <a target="_blank" href="">  </a> </li>
              </ul>
            </div>
          )} 

        {contentBox(
            "color1",
            "Bug Fixes",
            "April 22, 2022",
            "Feature Release Notes",
            <div>
              <ul>
             
                <li> <a target="_blank" href=""> TMS 718 </a> FE - Client Loom Feedback Apr 21 - Part One </li>
                <li> <a target="_blank" href=""> TMS 716 </a> FE - Subscription Plans List</li>
                <li> <a target="_blank" href=""> TMS 714 </a> Client Meeting Notes - Apr 13</li>
                <li> <a target="_blank" href=""> TMS 713 </a> Bugs 10 April</li>
                <li> <a target="_blank" href=""> </a> Contact Update and deletion </li>
                <li> <a target="_blank" href="">  </a> </li>
              </ul>
            </div>
          )}
        {contentBox(
            "color1",
            "Bug Fixes",
            "April 4, 2022",
            "Feature Release Notes",
            <div>
              <ul>
             
                <li> <a target="_blank" href=""> TMS 708 </a> UI Bugs 6 April </li>
                <li> <a target="_blank" href=""> TMS 627 </a> Redesign Wireframes for TMS Header</li>
                <li> <a target="_blank" href=""> TMS 706 </a> TMS Bugs 3 - Part Two</li>
                <li> <a target="_blank" href=""> TMS 705 </a> TMS Bugs 3 - Part One</li>
                <li> <a target="_blank" href=""> TMS 704 </a> Mortgage Renewal / Transfer</li>
                <li> <a target="_blank" href=""> TMS 703 </a> Home Refinance Changes</li>
                <li> <a target="_blank" href=""> TMS 699 </a> Client Meeting Notes - Part 2</li>
                <li> <a target="_blank" href="">  </a> </li>
              </ul>
            </div>
          )}


        {contentBox(
            "color1",
            "Bug Fixes",
            "Mar 25, 2022",
            "Feature Release Notes",
            <div>
              <ul>
             
                <li> <a target="_blank" href=""> TMS 700 </a> Update Rate Comparison </li>
                <li> <a target="_blank" href=""> TMS 697 </a> Bugs 20 March</li>
                <li> <a target="_blank" href=""> TMS 696 </a> Client Mentioned Bugs</li>
                <li> <a target="_blank" href=""> </a> Home Purchase Bugs - Plan name not working </li>
                <li> <a target="_blank" href=""> </a> Client Meeting Notes - Part 2 -Send Access bugs</li>
                
                <li> <a target="_blank" href="">  </a> </li>
              </ul>
            </div>
          )}

        {contentBox(
            "color1",
            "Bug Fixes",
            "Mar 17, 2022",
            "Feature Release Notes",
            <div>
              <ul>
              <li> Other Cost functionality modified - Independent Columns </li>
              <li> Defaults Options added </li>
              <li> All the remaining translations</li>
                <li> <a target="_blank" href=""> TMS 646 </a> Rental Property Purchase Changes </li>
                <li> <a target="_blank" href=""> TMS 683 </a> Home Page - Translation Bugs </li>
                <li> <a target="_blank" href=""> TMS 681 </a> Responsiveness</li>
                <li> <a target="_blank" href=""> TMS 678 </a> The Plans and Pricing section doesnt work</li>
                <li> <a target="_blank" href=""> TMS 684  </a> Contact Page Bugs </li>
                <li> <a target="_blank" href=""> TMS 631  </a> Pin resend - Forgot password </li>
                <li> <a target="_blank" href=""> TMS 586 </a> Home Page - Send access pop-up has too many scroll bars </li>
                
                <li> <a target="_blank" href="">  </a> </li>
              </ul>
            </div>
          )}

        {contentBox(
            "color1",
            "Bug Fixes",
            "Mar 08, 2022",
            "Feature Release Notes",
            <div>
              <ul>
                <li> <a target="_blank" href="">  </a> Translations for all main features </li>
                <li> <a target="_blank" href="">  </a> Login and signup responsiveness </li>
                <li> <a target="_blank" href="">  </a> Adding header to plans pages </li>
                <li> <a target="_blank" href="">  </a> Bug fix for plans not showing </li>
                <li> <a target="_blank" href="">  </a> Adding header to plans pages </li>
                <li> <a target="_blank" href="">  </a> </li>
              </ul>
            </div>
          )}

        {contentBox(
            "color1",
            "Bug Fixes",
            "Feb 25, 2022",
            "Feature Release Notes",
            <div>
              <ul>
                <li> <a target="_blank" href=""> TMS 641 </a> Design Changes - Home page </li>
                <li> <a target="_blank" href=""> TMS 642 </a> Design Changes - Strategy Session </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/365?modal=detail&selectedIssue=TMS-648&assignee=62008e00a29402006877c33f"> TMS - 648  </a> Contact Page Changes </li>
                <li> <a target="_blank" href=""> TMS 649 </a> PTT - Learn More Pages </li>
                <li> <a target="_blank" href=""> TMS 651, 663 </a> More language translations </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/365?modal=detail&selectedIssue=TMS-652&assignee=5be93bc7e45c2038d2845623"> TMS - 652 </a> Fix Issues with duplicate rate plan  </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/365?modal=detail&selectedIssue=TMS-650&assignee=5be93bc7e45c2038d2845623"> TMS - 652  </a> Fix Other Costs </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/365?modal=detail&selectedIssue=TMS-654&assignee=5be93bc7e45c2038d2845623"> TMS - 654 </a> Account Setting - payment and insurance</li>
                <li> <a target="_blank" href=""> TMS 668 </a> Purchase Additional Team Member License</li>
              </ul>
            </div>
          )}

        {contentBox(
            "color1",
            "Bug Fixes",
            "Feb 15, 2022",
            "Feature Release Notes",
            <div>
              <ul>
                <li> <a target="_blank" href="">  </a> Property transfer tax - BC & Ontario </li>
                <li> <a target="_blank" href="">  </a> More multi - language translations - Tooltips within accordions </li>
                <li> <a target="_blank" href="">  </a> Some UI - fixes within AccordionDetails </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/365?modal=detail&selectedIssue=TMS-654&assignee=5be93bc7e45c2038d2845623"> TMS - 654 </a> Update Billing information and payment Details from account settings </li>
              </ul>
            </div>
          )}

        {contentBox(
            "color1",
            "Bug Fixes",
            "Feb 08, 2022",
            "Feature Release Notes",
            <div>
              <ul>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-620&assignee=5be93bc7e45c2038d2845623"> TMS - 620 </a> Property Transfer Tax Changes - Making strategy session contacts as parent to strategy plan clients </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-620&assignee=5be93bc7e45c2038d2845623"> TMS - 620 </a> Property Transfer Tax Changes - Only specifying client type at strategy plan level </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-620&assignee=5be93bc7e45c2038d2845623"> TMS - 620 </a> Land transfer tax for Ontario - WIP </li>
                <li> <a target="_blank" href="">  </a>  </li>
              </ul>
            </div>
          )}

        {contentBox(
            "color1",
            "Bug Fixes",
            "Jan 31, 2022",
            "Feature Release Notes",
            <div>
              <ul>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-616&assignee=5be93bc7e45c2038d2845623"> TMS - 616 </a> Video Section Tutorilas </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-617&assignee=5be93bc7e45c2038d2845623"> TMS - 617 </a> Making plans for purchase dynamic </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-614&assignee=5be93bc7e45c2038d2845623"> TMS - 614 </a> Trial & expiry section </li>
              </ul>
            </div>
          )}

        {contentBox(
            "color1",
            "Bug Fixes",
            "Jan 25, 2022",
            "Feature Release Notes",
            <div>
              <ul>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-614&assignee=5be93bc7e45c2038d2845623">  </a> Trial / Plan Expiry </li>
                <li> <a target="_blank" href="">  </a> Minor UI fixes </li>
              </ul>
            </div>
          )}

        {contentBox(
            "color2",
            "Bug Fixes",
            "Jan 20, 2022",
            "Bug Fixes Release Notes",
            <div>
              <ul>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-539&assignee=5be93bc7e45c2038d2845623"> TMS - 539 </a> Cant update Rate Comparison </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-541&assignee=5be93bc7e45c2038d2845623"> TMS - 541 </a> More multi-language support (table header, accordion headings, tooltips) </li>
              </ul>
            </div>
          )}

        {contentBox(
            "color1",
            "Features",
            "Jan 14, 2022",
            "Features Release Notes",
            <div>
              <ul>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-606&assignee=5be93bc7e45c2038d2845623"> TMS - 606 </a> Enable / Disable sections based on roles </li>
              </ul>
            </div>
          )}

        {contentBox(
            "color2",
            "Bug Fixes",
            "Jan 14, 2022",
            "Bug Fixes Release Notes",
            <div>
              <ul>
                <li> <a target="_blank" href="">  </a> When adding new card, new card to appear on top </li>
                <li> <a target="_blank" href="">  </a> Changed payments per month to 2 dp </li>
                <li> <a target="_blank" href="">  </a> Add mortgage product options on Home refinance tab </li>
                <li> <a target="_blank" href="">  </a> Added option to change payment method when purchasing a plan </li>
                <li> <a target="_blank" href="">  </a> Added language support for tab and session headings (WIP) </li>
                <li> <a target="_blank" href="">  </a>  </li>
              </ul>
            </div>
          )}

        {contentBox(
            "color1",
            "Features",
            "Jan 11, 2022",
            "Features Release Notes",
            <div>
              <ul>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-600&assignee=5be93bc7e45c2038d2845623"> TMS-600 </a> Purchase Team Member License </li>
              </ul>
            </div>
          )}

          {contentBox(
            "color2",
            "Known Bugs",
            "Jan 11, 2022",
            "Known Bugs",
            <div>
              <ul>
                <li> <a target="_blank" href="">  </a> Manual refresh needed after creating new billing card details before they reflect </li>                
              </ul>
            </div>
          )}

        {contentBox(
            "color2",
            "Bug Fixes",
            "Jan 07, 2022",
            "Bug Fixes Release Notes",
            <div>
              <ul>
                <li> <a target="_blank" href="">  </a> Removed Total Savings from summary of before and after comparison </li>
                <li> <a target="_blank" href="">  </a> Fixed bug on mortgage refinance breakdown to allow the second column to change as the first is changed </li>
                <li> <a target="_blank" href="">  </a> Added total Mortgage amount row on first card </li>
                <li> <a target="_blank" href="">  </a> Fixed bug for auto-change of Additional funds </li>
                <li> <a target="_blank" href="">  </a>  </li>
              </ul>
            </div>
          )}

          {contentBox(
            "color2",
            "Bug Fixes",
            "Jan 05, 2022",
            "Bug Fixes Release Notes",
            <div>
              <ul>
                <li>A release note refers to the technical documentation </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-596&assignee=5be93bc7e45c2038d2845623"> TMS-596 </a> Dec22 Meeting bugs on Home Re-finance  </li>
              </ul>
            </div>
          )}

        {contentBox(
            "color1",
            "Features",
            "Dec 30, 2021",
            "Features Release Notes",
            <div>
              <ul>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-593&assignee=5be93bc7e45c2038d2845623"> TMS-593 </a> Refer and Earn </li>
              </ul>
            </div>
          )}

          {contentBox(
            "color2",
            "Bug Fixes",
            "Dec 30, 2021",
            "Bug Fixes Release Notes",
            <div>
              <ul>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-596&assignee=5be93bc7e45c2038d2845623"> TMS-596 </a> Dec22 Meeting bugs on Home Re-finance  </li>
              </ul>
            </div>
          )}

          {contentBox(
            "color2",
            "Bug Fixes",
            "Dec 23, 2021",
            "Bug Fixes Release Notes",
            <div>
              <ul>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-527&assignee=5be93bc7e45c2038d2845623"> TMS - 527   </a> Cant select Province by using keyboard  </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-543&assignee=5be93bc7e45c2038d2845623"> TMS - 543 </a> Fix bug when creating SS </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-544&assignee=5be93bc7e45c2038d2845623"> TMS - 544 </a> Fix Alignment issues in Home Purchase SS for Down Payment items </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-545&assignee=5be93bc7e45c2038d2845623"> TMS - 545 </a> Fix issues in Staging Session </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-547&assignee=5be93bc7e45c2038d2845623"> TMS - 547 </a> Allow the User to edit the folder </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-548&assignee=5be93bc7e45c2038d2845623"> TMS - 548 </a> Missing Footer  </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-592&assignee=5be93bc7e45c2038d2845623"> TMS - 592 </a> Wrong photo in contacts table </li>
              </ul>
            </div>
          )}
          {contentBox(
            "color2",
            "Known Bugs",
            "Dec 23, 2021",
            "Known Bugs",
            <div>
              <ul>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-544&assignee=5be93bc7e45c2038d2845623"> TMS - 544 </a> Alignment issues for down payments </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-545&assignee=5be93bc7e45c2038d2845623"> TMS - 545 </a> Transitions in staging </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-591&assignee=5be93bc7e45c2038d2845623"> TMS - 591 </a> Static photo in contact card </li>                
              </ul>
            </div>
          )}

          {contentBox(
            "color1",
            "Features",
            "Dec 16, 2021",
            "Features Release Notes",
            <div>
              <ul>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-541&assignee=5be93bc7e45c2038d2845623"> TMS - 541 </a> Multilinguism Handling </li>
              </ul>
            </div>
          )}

          {contentBox(
            "color2",
            "Bug Fixes",
            "Dec 16, 2021",
            "Bug Fixes Release Notes",
            <div>
              <ul>
                <li>A release note refers to the technical documentation </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-508&assignee=5be93bc7e45c2038d2845623"> TMS - 508 </a> Remember Me Not Working in Login </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-526&assignee=5be93bc7e45c2038d2845623"> TMS - 526 </a> Forgot Password Page </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-529&assignee=5be93bc7e45c2038d2845623"> TMS - 529 </a> The Facebook, Twitterm Insta, LinkedIN icons dont work </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-534&assignee=5be93bc7e45c2038d2845623"> TMS - 534 </a> WHen creating a new client, it doesnt save as Client Type </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-535&assignee=5be93bc7e45c2038d2845623"> TMS - 535 </a> Extra white space at the top for no reason above logo </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-536&assignee=5be93bc7e45c2038d2845623"> TMS - 536 </a> Fix Pagination Issue </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-537&assignee=5be93bc7e45c2038d2845623"> TMS - 537 </a> Need an option to get out of the Pricing Page </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-538&assignee=5be93bc7e45c2038d2845623"> TMS - 538 </a> Table Pagination doesn't give any results next page </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-540&assignee=5be93bc7e45c2038d2845623"> TMS - 540 </a> Forgot Password Page is loading wrong link for SignIn page </li>                
              </ul>
            </div>
          )}
          {contentBox(
            "color3",
            "Known Bugs",
            "Dec 16, 2021",
            "Known Bugs",
            <div>
              <ul>
              <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-527&assignee=5be93bc7e45c2038d2845623"> TMS - 527   </a> Cant select Province by using keyboard  </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-543&assignee=5be93bc7e45c2038d2845623"> TMS - 543 </a> Fix bug when creating SS </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-544&assignee=5be93bc7e45c2038d2845623"> TMS - 544 </a> Fix Alignment issues in Home Purchase SS for Down Payment items </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-545&assignee=5be93bc7e45c2038d2845623"> TMS - 545 </a> Fix issues in Staging Session </li>
                <li> <a target="_blank" href="https://247labs.atlassian.net/jira/software/c/projects/TMS/boards/357?modal=detail&selectedIssue=TMS-547&assignee=5be93bc7e45c2038d2845623"> TMS - 547 </a> Allow the User to edit the folder </li>
              </ul>

              <br/>
              A release note refers to the technical documentation produced and
              distributed alongside the launch of a new software product or a
              product update (e.g., recent changes, feature enhancements, or bug
              fixes). It very briefly describes a new product or succinctly
              details specific changes included in a product update.
              <br />
              <br />
              The job of drafting these frequently falls on product managers.
              The primary target audience is the product user, but a release
              note can also be used internally.
              <br />
              <br />
              While a release note provides relevant information about a new
              product or product update, it’s not a substitute for a user guide
              or other in-depth product documentation.
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );

  return (
    <footer className={classes.footer}>
   </footer>
  )
}

const useStyles = makeStyles((theme) => ({
  footer: {
    // backgroundColor: "#F4F7FC",
    // flexDirection: "row",
    // paddingBottom: "20px",
    // paddingTop: "20px",
    // textAlign: "right",
  },
  version: {
    fontWeight: "bold",
    cursor: "pointer",
    fontSize: 13,
  },

  poweredBy: {
    // margin: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    // justifyContent: "flex-end",
    color: "#686D76",
    // fontSize: 12,
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  footerLogo: {
    width: 80,
    marginLeft: 5,
    fontSize: 14,
    cursor: "pointer",
  },
  dialog: {
    textAlign: "center",
    backgroundImage: `url(${interior_hero_bg}) `,
    backgroundSize: "cover",
    padding: "0px 0 40px",
  },
  dialogTitle: {
    color: "#106EE8",
    textAlign: "left",
    backgroundSize: "cover",
    fontSize: 26,
    paddingLeft: "10px",
    display: " inline-block",
  },

  content: {},
  heading: {},
  topic: {
    color: "#fff",
    padding: "3px 18px",
    borderRadius: "25px",
    fontSize: "11px",
    marginRight: "5px",
  },
  date: {
    fontSize: "11px",
  },
  color1: {
    backgroundColor: "#0BAC4F",
  },
  color2: {
    backgroundColor: "#FF8048",
  },
  color3: {
    backgroundColor: "#EC6364",
  },
  cotainer: {
    margin: "30px 0",
  },
  dialogContentRoot: {
    padding: "0",
  },
  dialogContent: {
    padding: "8px 24px",
  },
  close: {
    textAlign: "end",
    fontSize: "18px",
    paddingRight: "40px",
    cursor: "pointer",
  },
  link: {
    cursor: "pointer",
  },
}));
