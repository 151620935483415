import axios, {
  AxiosError,
  AxiosPromise,
  AxiosResponse,
  AxiosRequestConfig,
} from "axios";
import { Dispatch } from "redux";
import { setFetching } from "../@store/reducers/core";
// import APP_CONFIG from "../config";
import { store } from "../@store";
import {toast} from 'react-toastify';
import { logoutUser } from "../@store/reducers/auth";

// import { useSelector } from "../pages/node_modules/react-redux";

const apiRequest = function (options: any): any {
  return (dispatch: Dispatch, getState: any): AxiosPromise => {
    // TODO: dispatch reset validators

    const onSuccess = (response: AxiosResponse): AxiosResponse => {
      if (
        response.data &&
        response.data.message === "You are not allowed to do this action."
      )
        dispatch(logoutUser())
          .then((result: AxiosResponse) => {})
          .catch((err: AxiosError) => {});
      dispatch(setFetching(false));
      return response;
    };

    const onError = (error: AxiosError): AxiosPromise => {
      dispatch(setFetching(false));

      console.info("Request ERROR :: ", error);
      // console.info("APP_CONFIG", APP_CONFIG)
      if (error.response) {
        if (error.response.data.message && error.response.status === 500) {
            toast.error(error.response.data.message)
        }
        // if (error.response.data.errors) {
        //     dispatch(setValidationErrors(error.response.data.errors))
        // }
      }

      return Promise.reject(error.response || error.message);
    };

    // accessing the access token from the auth reducer

    const access_token = getState().auth.token;

    return axios({
      baseURL: process.env.REACT_APP_API_URL,
      ...options,
      headers: {
        Authorization: `Bearer ${access_token}`,
        // Accept: "application/json",

        Accept: "*/*",
        "Content-Type": "application/json",
        ...options.headers,
      },
    })
      .then(onSuccess)
      .catch(onError);
  };
};

export const api = function (options: any, onSuccess: any, onError: any): any {
  return (dispatch: Dispatch, getState: any): AxiosPromise => {
    const access_token = getState().auth.token;

    return axios({
      baseURL: process.env.REACT_APP_API_URL,
      ...options,
      headers: {
        Authorization: `Bearer ${access_token}`,
        Accept: "*/*",
        // Accept: "application/json",
        "Content-Type": "application/json",
        ...options.headers,
      },
    })
      .then((res) => dispatch(onSuccess(res)))
      .catch((err) => dispatch(onError(err)));
  };
};

export function ConfigureLoader() {
  axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      store.dispatch({
        type: "SHOW_LOADER",
      });

      return config;
    },
    (error: AxiosError) => {
      store.dispatch({ type: "HIDE_LOADER" });
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      store.dispatch({
        type: "HIDE_LOADER",
      });

      return response;
    },
    (error: AxiosError) => {
      store.dispatch({ type: "HIDE_LOADER" });
      return Promise.reject(error);
    }
  );
}

export default apiRequest;
