import { RESET_STORE } from "../types";
import apiRequest from "../../@core/axios";

// Initial state
export const initialState = {};

export function addMortgageRefinance(strategy_plan_id: string) {
  return apiRequest({
    url: `client/strategies/${strategy_plan_id}/mortgage-refinances/add`,
    method: "POST",
  });
}

export function brokerAddMortgageRefinance(strategy_plan_id: string) {
  return apiRequest({
    url: `broker/strategies/${strategy_plan_id}/mortgage-refinances/add`,
    method: "POST",
  });
}

export function updateMortgageRefinance(
  strategy_id: string,
  mortgage_refinance_id: string,
  data: any
) {
  return apiRequest({
    url: `client/strategies/${strategy_id}/mortgage-refinances/${mortgage_refinance_id}/update`,
    method: "POST",
    data,
  });
}

export function brokerUpdateMortgageRefinance(
  strategy_id: string,
  mortgage_refinance_id: string,
  data: any
) {
  return apiRequest({
    url: `broker/strategies/${strategy_id}/mortgage-refinances/${mortgage_refinance_id}/update`,
    method: "POST",
    data,
  });
}

export function duplicateMortgageRefinance(strategy_id: string, mortgage_refinance_id: string) {
  return apiRequest({
    url: `client/strategies/${strategy_id}/mortgage-refinances/${mortgage_refinance_id}/duplicate`,
    method: "POST",
  });
}

export function brokerDuplicateMortgageRefinance(strategy_id: string, mortgage_refinance_id: string) {
  return apiRequest({
    url: `broker/strategies/${strategy_id}/mortgage-refinances/${mortgage_refinance_id}/duplicate`,
    method: "POST",
  });
}

export function deleteMortgageRefinance(strategy_id: string, mortgage_refinance_id: string) {
  return apiRequest({
    url: `client/strategies/${strategy_id}/mortgage-refinances/${mortgage_refinance_id}`,
    method: "DELETE",
  });
}

export function brokerDeleteMortgageRefinance(strategy_id: string, mortgage_refinance_id: string) {
  return apiRequest({
    url: `broker/strategies/${strategy_id}/mortgage-refinances/${mortgage_refinance_id}`,
    method: "DELETE",
  });
}

export function addMortgageProductOptions(id: string) {
  return apiRequest({
    url: `client/strategies/${id}/product-options/add`,
    method: "POST",
  });
}

export function brokerAddMortgageProductOptions(id: string) {
  return apiRequest({
    url: `broker/strategies/${id}/product-options/add`,
    method: "POST",
  });
}

export function selectMortgageProductOptions(strategy_id: string, id: string) {
  return apiRequest({
    url: `client/strategies/${strategy_id}/product-options/${id}/select`,
    method: "POST",
  });
}

export function brokerSelectMortgageProductOptions(strategy_id: string, id: string) {
  return apiRequest({
    url: `broker/strategies/${strategy_id}/product-options/${id}/select`,
    method: "POST",
  });
}

export function brokerAddMortgageRefinanceItems(
  strategy_id: string,
  mortgage_refinance_id: string,
  data: any
) {
  return apiRequest({
    url: `broker/strategies/${strategy_id}/mortgage-refinances/${mortgage_refinance_id}/items/add`,
    method: "POST",
    data,
  });
}

export function addMortgageRefinanceItems(
  strategy_id: string,
  mortgage_refinance_id: string,
  data: any
) {
  return apiRequest({
    url: `client/strategies/${strategy_id}/mortgage-refinances/${mortgage_refinance_id}/items/add`,
    method: "POST",
    data,
  });
}

export function updateMortgageRefinanceItems(
  strategy_id: string,
  mortgage_refinance_id: string,
  mortgage_refinance_item_id: string,
  data: any
) {
  return apiRequest({
    url: `client/strategies/${strategy_id}/mortgage-refinances/${mortgage_refinance_id}/items/${mortgage_refinance_item_id}/update`,
    method: "POST",
    data,
  });
}

export function brokerUpdateMortgageRefinanceItems(
  strategy_id: string,
  mortgage_refinance_id: string,
  mortgage_refinance_item_id: string,
  data: any
) {
  return apiRequest({
    url: `broker/strategies/${strategy_id}/mortgage-refinances/${mortgage_refinance_id}/items/${mortgage_refinance_item_id}/update`,
    method: "POST",
    data,
  });
}

export function brokerDuplicateMortgageRefinanceItems(
  strategy_id: string,
  mortgage_refinance_id: string,
  mortgage_refinance_item_id: string
) {
  return apiRequest({
    url: `broker/strategies/${strategy_id}/mortgage-refinances/${mortgage_refinance_id}/items/${mortgage_refinance_item_id}/duplicate`,
    method: "POST",
  });
}

export function duplicateMortgageRefinanceItems(
  strategy_id: string,
  mortgage_refinance_id: string,
  mortgage_refinance_item_id: string
) {
  return apiRequest({
    url: `client/strategies/${strategy_id}/mortgage-refinances/${mortgage_refinance_id}/items/${mortgage_refinance_item_id}/duplicate`,
    method: "POST",
  });
}

export function brokerDeleteMortgageRefinanceItem(
  strategy_id: string,
  mortgage_refinance_id: string,
  mortgage_refinance_item_id: string
) {
  return apiRequest({
    url: `broker/strategies/${strategy_id}/mortgage-refinances/${mortgage_refinance_id}/items/${mortgage_refinance_item_id}`,
    method: "DELETE",
  });
}

export function deleteMortgageRefinanceItem(
  strategy_id: string,
  mortgage_refinance_id: string,
  mortgage_refinance_item_id: string
) {
  return apiRequest({
    url: `client/strategies/${strategy_id}/mortgage-refinances/${mortgage_refinance_id}/items/${mortgage_refinance_item_id}`,
    method: "DELETE",
  });
}

export function brokerAddProductOptionItems(strategy_id: string, product_option_id: string, data: any) {
  return apiRequest({
    url: `broker/strategies/${strategy_id}/product-options/${product_option_id}/items/add`,
    method: "POST",
    data,
  });
}

export function addProductOptionItems(strategy_id: string, product_option_id: string, data: any) {
  return apiRequest({
    url: `client/strategies/${strategy_id}/product-options/${product_option_id}/items/add`,
    method: "POST",
    data,
  });
}
export function deleteProductOption(strategy_id:string, id: string) {
  return apiRequest({
    url: `client/strategies/${strategy_id}/product-options/${id}`,
    method: "DELETE",
  });
}

export function brokerDeleteProductOption(strategy_id:string, id: string) {
  return apiRequest({
    url: `broker/strategies/${strategy_id}/product-options/${id}`,
    method: "DELETE",
  });
}

export function deleteProductOptionItems(strategy_id: string, product_option_id: string, item_id: string) {
  return apiRequest({
    url: `client/strategies/${strategy_id}/product-options/${product_option_id}/items/${item_id}`,
    method: "DELETE",
  });
}

export function brokerDeleteProductOptionItems(strategy_id: string, product_option_id: string, item_id: string) {
  return apiRequest({
    url: `broker/strategies/${strategy_id}/product-options/${product_option_id}/items/${item_id}`,
    method: "DELETE",
  });
}

export function brokerUpdateProductOptionItem(
  strategy_id: string,
  product_option_id: string,
  product_option_item_id: string,
  data: any
): Promise<any> {
  return apiRequest({
    url: `broker/strategies/${strategy_id}/product-options/${product_option_id}/items/${product_option_item_id}/update`,
    method: "POST",
    data,
  });
}

export function getRateForecasts(): Promise<any> {
  return apiRequest({
    url: `broker/rate-forecasts`,
    method: "GET"
  })
}

export function saveRateForecast(data: any): Promise<any> {
  return apiRequest({
    url: `broker/rate-forecasts/add`,
    method: "POST",
    data
  })
}

export function updateRateForecast(id:number, data: any): Promise<any> {
  return apiRequest({
    url: `broker/rate-forecasts/${id}/update`,
    method: "POST",
    data
  })
}

export function updateProductOptionItem(
  strategy_id: string,
  product_option_id: string,
  product_option_item_id: string,
  data: any
): Promise<any> {
  return apiRequest({
    url: `client/strategies/${strategy_id}/product-options/${product_option_id}/items/${product_option_item_id}/update`,
    method: "POST",
    data,
  });
}

export function updateMortgageRefinanceAfters(
  strategy_id: string,
  mortgage_refinance_after_id: string,
  data: any
): Promise<any> {
  return apiRequest({
    url: `client/strategies/${strategy_id}/mortgage-refinance-afters/${mortgage_refinance_after_id}/update`,
    method: "POST",
    data,
  });
}

export function brokerUpdateMortgageRefinanceAfters(
  strategy_id: string,
  mortgage_refinance_after_id: string,
  data: any
): Promise<any> {
  return apiRequest({
    url: `broker/strategies/${strategy_id}/mortgage-refinance-afters/${mortgage_refinance_after_id}/update`,
    method: "POST",
    data,
  });
}

export function ProductOptionReducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
}
