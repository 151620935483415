import { Dispatch } from "redux";
import {
  RESET_AUTH,
  RESET_STORE,
  SET_AUTH_TOKENS,
  SET_USER_DATA,
  SET_USER_REGISTER_DATA,
} from "../types";
import apiRequest from "../../@core/axios";

// Initial state
export const initialState = {
  token: null,
  user: {},
  userRegister: {},
  userId: null,
};

export function loginUser(data: any): Promise<any> {
  return apiRequest({
    url: "login",
    method: "POST",
    data,
  });
}

export function loginDeal(data: any, type: any): Promise<any> {
  return apiRequest({
    url: `${type}/auth/sign-in`,
    method: "POST",
    data
  })
}

export function clientLogin(data: any): Promise<any> {
  return apiRequest({
    url: "client/auth/sign-in",
    method: "POST",
    data
  })
}

export function logoutUser() {
  return apiRequest({
    url: "logout",
    method: "GET",
  });
}
export function signupUser(data: any): Promise<any> {
  return apiRequest({
    url: "register",
    method: "POST",
    data,
  });
}

export function resendEmailVerification(data: any): Promise<any> {
  return apiRequest({
    url: "verify-email/resend",
    method: "POST",
    headers: {
      Authorization: `Bearer ${data}`,
    },
  });
}

export function getUserDetails(): Promise<any> {
  return apiRequest({
    url: "user",
    method: "GET",
  });
}

export function updateUserDetails(data: any): Promise<any> {
  return apiRequest({
    url: "user/update",
    method: "POST",
    data,
  });
}

export function updateUserAvatar(data: any): Promise<any> {
  return apiRequest({
    url: "user/update-avatar",
    method: "POST",
    data,
  });
}

export function updateUserPassword(data: any): Promise<any> {
  return apiRequest({
    url: "user/update-password",
    method: "PUT",
    data,
  });
}

export function forgotPassword(data: any): Promise<any> {
  return apiRequest({
    url: "forgot-password",
    method: "POST",
    data,
  });
}

export function verifyPin(data: any): Promise<any> {
  return apiRequest({
    url: "forgot-password/verify",
    method: "POST",
    data,
  });
}

export function changePassword(data: any): Promise<any> {
  return apiRequest({
    url: "forgot-password/reset",
    method: "POST",
    data,
  });
}

export function deleteUserAccount(data: any): Promise<any> {
  return apiRequest({
    url: '/user/delete-account',
    method: 'POST',
    data
  })
}

export function getLoginPageImage(): Promise<any> {
  return apiRequest({
    url: "options/login-background-image",
    method: "GET",
  });
}

export function resetStore() {
  return {
    type: RESET_STORE,
  };
}

export function setUserRegisterData(data: any) {
  return {
    type: SET_USER_REGISTER_DATA,
    payload: data,
  };
}

export function setUserData(data: any) {
  return {
    type: SET_USER_DATA,
    payload: data,
  };
}

export function setUserTokens(data: any) {
  return {
    type: SET_AUTH_TOKENS,
    payload: data,
  };
}

export function setTutorialWatched(): Promise<any> {
  return apiRequest({
    url: "user/settings/tutorials/watched",
    method: "POST",
  });
}

export function AuthReducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        user: action.payload,
      };
    case SET_USER_REGISTER_DATA:
      return {
        ...state,
        userRegister: action.payload,
      };
    case SET_AUTH_TOKENS:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_AUTH:
      return initialState;
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
}
