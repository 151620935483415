import apiRequest from "../../@core/axios";
import {
  CORE_SET_FETCHING,
  CORE_SET_VALIDATION_ERRORS,
  RESET_STORE,
} from "../types";
import {
  CORE_SET_LENDERS_LIST,
  CORE_SET_PROVINCE_LIST,
  CORE_SET_SS_MODAL_ENABLED,
} from "./../types";

// Initial state
export const initialState = {
  fetching: false,
  ssModalEnabled: false,
  validationErrors: {},
  provinceList: {},
  lendersList: {},
};

export function getProvinceList() {
  return apiRequest({
    url: "provinces",
    method: "GET",
  });
}

export function getInviteInfo(id: any) {
  return apiRequest({
    url: `invitations/${id}`,
    method: "GET",
  });
}

export function getMemberInviteInfo(id: any) {
  return apiRequest({
    url: `company-invitations/${id}`,
    method: "GET",
  });
}

export function registerInvitation(id: any, data: any) {
  return apiRequest({
    url: `invitations/${id}/register`,
    method: "POST",
    data,
  });
}

export function registerMemberInvitation(id: any, data: any) {
  return apiRequest({
    url: `company-invitations/${id}/register`,
    method: "POST",
    data,
  });
}

export function acceptMemberInvitation(id: any) {
  return apiRequest({
    url: `company-invitations/${id}/accept`,
    method: "POST",
  
  });
}


export function getLendersList(type: string) {
  return apiRequest({
    url: `${type}/lenders?limit=100`,
    method: "GET",
  });
}

export function setSSModalEnabled(ssModalEnabled = true) {
  return {
    type: CORE_SET_SS_MODAL_ENABLED,
    payload: { ssModalEnabled },
  };
}

export function setProvinceList(data: any) {
  return {
    type: CORE_SET_PROVINCE_LIST,
    payload: data,
  };
}

export function setLendersList(data: any) {
  return {
    type: CORE_SET_LENDERS_LIST,
    payload: data,
  };
}

export function setValidationErrors(errors = {}) {
  return {
    type: CORE_SET_VALIDATION_ERRORS,
    payload: errors,
  };
}

export function setFetching(fetching = true) {
  return {
    type: CORE_SET_FETCHING,
    payload: { fetching },
  };
}

export function CoreReducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case CORE_SET_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: action.payload,
      };
    case CORE_SET_SS_MODAL_ENABLED:
      return {
        ...state,
        ssModalEnabled: action.payload.ssModalEnabled,
      };

    case CORE_SET_PROVINCE_LIST:
      return {
        ...state,
        provinceList: action.payload,
      };
    case CORE_SET_LENDERS_LIST:
      return {
        ...state,
        lendersList: action.payload,
      };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
}
